import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

import dashboardItems from "../components/sidebar/dashboardItems";

const Dashboard = ({ children }) => {

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('user_data')===null || localStorage.getItem('user_data')===undefined || JSON.parse(localStorage.getItem('user_data')).type==='CLIENTE') {
      navigate("/");
    }
  }, []);

  return(
    <React.Fragment>
      <Wrapper>
        <Sidebar items={dashboardItems} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  )
}

export default Dashboard;
