import React, { useState, useEffect } from 'react';
import { 
  Card,
  Container,
  Col,
  InputGroup,
  Button,
  ButtonGroup,
  Form,
  Row,
  Modal
} from "react-bootstrap";
import ButtonMaterial from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


import { logedServices } from '../../services/logedServices';

const TableRes = () => {

  setLocale({
    mixed: {
      required: 'Este campos es requerido.'
    }
  })
  /*
  setLocale({
    mixed: {
      required: '${path} is requerido'
    }
  })
  */

  const confirmar = (noti) => {
    confirmAlert({
      title: 'Confirmación',
      message: 'Estás seguro que deseas eliminar el registro?',
      buttons: [
        {
          label: 'Si',
          onClick: () => {
            deleteNoti(noti)
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


  const [lastPage, setLastPage] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [searchState, setSearchState] = useState(null);
  const [paramState, setParamState] = useState('id');
  const [orderState, setOrderState] = useState('ASC');
  const [notis, setNotis] = useState(null);
  const [desarrollos, setDesarrollos] = useState(null);
  const [modelos, setModelos] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [dataActive, setDataActive] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleModalAdd = () => setModalAdd(!modalAdd);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleModalUpdate = () => setModalUpdate(!modalUpdate);

  useEffect(() => {
      getNotis(1,searchState,paramState,orderState);
      getDesarrollos();
  }, []);

  useEffect(() => {
    if (dataActive!==null && dataActive.modelo_notificacions!==null) {
      getModelosFromDesarrollo(dataActive.modelo_notificacions.modelo.desarrollo.id);
    }else{
      setModelos(null);
    }
  }, [dataActive]);

  function getNotis(page, search, param, order){
      setDisabled(true);
      logedServices.getNotis(page, 10, search, param, order)
      .then(response => {
        if (response.status===200) {
          setPageSelected(page);
          console.log('response.data===>');
          console.log(response.data);
          setNotis(response.data);
          setLastPage(response.data.last_page);
          setSearchState(search);
          setParamState(param);
          setOrderState(order);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function deleteNoti(noti){
      setDisabled(true);
      logedServices.deleteNoti(noti.id)
      .then(response => {
        if (response.status===204) {
          getNotis(pageSelected, searchState, paramState, orderState);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    getNotis(value, searchState, paramState, orderState);
  };

  const handleChangeParam = event => {
    let param_ = event.target.value;
    setParamState(param_);
    getNotis(pageSelected, searchState, param_, orderState);
  };

  const handleChangeOrder = event => {
    let order_ = event.target.value;
    setOrderState(order_);
    getNotis(pageSelected, searchState, paramState, order_);
  };

  function getDesarrollos(){
      setDisabled(true);
      logedServices.getAllDesarrollos()
      .then(response => {
        if (response.status===200) {
          setDesarrollos(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function getModelosFromDesarrollo(desarrollo_id){
      setDisabled(true);
      logedServices.getModelosFromDesarrollo(desarrollo_id)
      .then(response => {
        if (response.status===200) {
          setModelos(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }



  return (
  <React.Fragment> 
    <Card>
      <Card.Body>
          
            
                <Row>
                  {notis!==null && notis.data.length>0 ? 
                  <React.Fragment>
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                      <Formik
                        initialValues={{
                          busqueda: ''
                        }}
                        onSubmit={(values) => {
                          getNotis(1, values.busqueda, paramState, orderState);
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Form.Label
                              className="visually-hidden"
                              htmlFor="busqueda"
                            >
                              Busqueda
                            </Form.Label>
                            <InputGroup>
                              <Form.Control 
                                id="busqueda"
                                name="busqueda"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.busqueda}
                              />
                              <Button type="submit" disabled={disabled} color="primary">
                                <SearchIcon />
                              </Button>
                            </InputGroup>
                          </form>
                        )}
                      </Formik>
                    </Col>
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                      <Form.Label
                        className="visually-hidden"
                        htmlFor="campo"
                      >
                        Orden
                      </Form.Label>
                        <Row>
                          <Col>
                            <Form.Select
                              type="select"
                              value={paramState}
                              onChange={handleChangeParam}
                            >
                              <option value="id">
                                ID
                              </option>
                              <option value="titulo">
                                Título
                              </option>
                              <option value="mensaje">
                                Mensaje
                              </option>
                            </Form.Select>
                          </Col>
                          <Col>
                            <Form.Select
                              type="select"
                              value={orderState}
                              onChange={handleChangeOrder}
                            >
                              <option value="asc">
                                ASC
                              </option>
                              <option value="desc">
                                DESC
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                    </Col>
                  </React.Fragment> 
                  : ''}
                  <Col xs="12" md="6" lg="4" xl="6" className="mb-3 text-right">
                    <ButtonMaterial disabled={disabled} variant="contained" endIcon={<AddIcon />} onClick={toggleModalAdd}>
                      Agregar
                    </ButtonMaterial>
                  </Col>
                  {notis!==null && notis.data.length>0 ? 
                  <Col xs="12" className="mb-3">
                    <Table className="">
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>TITULO</Th>
                          <Th>DESCRIPCION</Th>
                          <Th>DESARROLLO</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {notis.data.map((noti) => (
                          <Tr key={noti.id}>
                            <Td>{noti.id}</Td>
                            <Td>{noti.titulo}</Td>
                            <Td>{noti.mensaje}</Td>
                            <Td>
                              {noti.modelo_notificacions!==null ? 
                                <React.Fragment>
                                  <div className="block_ctrl">Desarrollo: {noti.modelo_notificacions.modelo.desarrollo.nombre}</div>
                                  <div className="block_ctrl">Modelo: {noti.modelo_notificacions.modelo.titulo_anuncio}</div>
                                </React.Fragment>
                              :''}
                              {noti.desarrollo_notificacions!==null ? 
                                <React.Fragment>
                                  <div className="block_ctrl">Desarrollo: {noti.desarrollo_notificacions.desarrollo.nombre}</div>
                                </React.Fragment>
                              :''}
                            </Td>
                            <Td>
                              <ButtonGroup
                                className="my-2"
                                size="sm"
                              >
                                <Button disabled={disabled} onClick={() => {setDataActive(noti);toggleModalUpdate();}} variant="outline-primary">
                                  Editar
                                </Button>
                                <Button disabled={disabled} onClick={() => {setDataActive(noti);confirmar(noti);}} variant="outline-primary">
                                  Borrar
                                </Button>
                              </ButtonGroup>
                            </Td>   
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Col>
                  : ''}
                </Row>
            
          <Pagination className="mt-3" count={lastPage} page={pageSelected} onChange={handleChangePage} />
      </Card.Body>
    </Card>

    <Modal show={modalAdd} onHide={() => {setModalAdd(false); setModelos(null);}} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            titulo: '',
            mensaje: '',
            desarrollo_id: '',
            modelo_id: ''
          }}
          validationSchema={Yup.object().shape({
            titulo: Yup.string().required().max(60, 'El título no debe tener más de 60 caracteres'),
            mensaje: Yup.string().required().max(150, 'El mensaje no debe tener más de 150 caracteres')
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.addNoti(values)
              .then(response => {
                getNotis(1, searchState, paramState, orderState);
                toggleModalAdd();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Nueva notificación</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.titulo && errors.titulo)}
                    fullWidth
                    helperText={touched.titulo && errors.titulo}
                    label="Título"
                    margin="normal"
                    name="titulo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.titulo}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.mensaje && errors.mensaje)}
                    fullWidth
                    helperText={touched.mensaje && errors.mensaje}
                    label="Mensaje"
                    margin="normal"
                    name="mensaje"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.mensaje}
                    variant="outlined"
                    multiline
                    maxRows={4}
                    rows={2}
                  />
                  {desarrollos!==null && desarrollos.data.length>0 ? 
                  <TextField
                    select
                    autoFocus={true}
                    error={Boolean(touched.desarrollo_id && errors.desarrollo_id)}
                    fullWidth
                    helperText={touched.desarrollo_id && errors.desarrollo_id}
                    label="Desarrollo"
                    margin="normal"
                    name="desarrollo_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.desarrollo_id}
                    variant="outlined"
                  >
                    {desarrollos.data.map((desarrollo) => (
                      <MenuItem key={desarrollo.id} value={desarrollo.id} onClick={() => {getModelosFromDesarrollo(desarrollo.id)}}>
                        {desarrollo.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  {modelos!==null && modelos.data.length>0 ? 
                  <TextField
                    select
                    autoFocus={true}
                    error={Boolean(touched.modelo_id && errors.modelo_id)}
                    fullWidth
                    helperText={touched.modelo_id && errors.modelo_id}
                    label="Propiedad"
                    margin="normal"
                    name="modelo_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.modelo_id}
                    variant="outlined"
                  >
                    {modelos.data.map((modelo) => (
                      <MenuItem key={modelo.id} value={modelo.id}>
                        {modelo.titulo_anuncio}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalAdd}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalUpdate} onHide={() =>{setModalUpdate(false);setModelos(null);} } backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            id: dataActive!==null?dataActive.id:'',
            titulo: dataActive!==null?dataActive.titulo:'',
            mensaje: dataActive!==null?dataActive.mensaje:'',
            desarrollo_id: dataActive!==null ? (dataActive.desarrollo_notificacions!==null ? dataActive.desarrollo_notificacions.desarrollo.id : dataActive.modelo_notificacions.modelo.desarrollo.id) :'',
            modelo_id: dataActive!==null ? (dataActive.modelo_notificacions!==null ? dataActive.modelo_notificacions.modelo.id : '') :'',
          }}
          validationSchema={Yup.object().shape({
            titulo: Yup.string().required().max(60, 'El título no debe tener más de 60 caracteres'),
            mensaje: Yup.string().required().max(150, 'El mensaje no debe tener más de 150 caracteres')
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updateNoti(values)
              .then(response => {
                getNotis(pageSelected, searchState, paramState, orderState);
                toggleModalUpdate();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Actualizar</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.titulo && errors.titulo)}
                    fullWidth
                    helperText={touched.titulo && errors.titulo}
                    label="Título"
                    margin="normal"
                    name="titulo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.titulo}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.mensaje && errors.mensaje)}
                    fullWidth
                    helperText={touched.mensaje && errors.mensaje}
                    label="Mensaje"
                    margin="normal"
                    name="mensaje"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.mensaje}
                    variant="outlined"
                    multiline
                    maxRows={4}
                    rows={2}
                  />
                  {desarrollos!==null && desarrollos.data.length>0 ? 
                  <TextField
                    select
                    autoFocus={true}
                    error={Boolean(touched.desarrollo_id && errors.desarrollo_id)}
                    fullWidth
                    helperText={touched.desarrollo_id && errors.desarrollo_id}
                    label="Desarrollo"
                    margin="normal"
                    name="desarrollo_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.desarrollo_id}
                    variant="outlined"
                  >
                    {desarrollos.data.map((desarrollo) => (
                      <MenuItem key={desarrollo.id} value={desarrollo.id} onClick={() => {getModelosFromDesarrollo(desarrollo.id);setFieldValue("modelo_id", '');}}>
                        {desarrollo.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  {modelos!==null && modelos.data.length>0 ? 
                  <TextField
                    select
                    autoFocus={true}
                    error={Boolean(touched.modelo_id && errors.modelo_id)}
                    fullWidth
                    helperText={touched.modelo_id && errors.modelo_id}
                    label="Propiedad"
                    margin="normal"
                    name="modelo_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.modelo_id}
                    variant="outlined"
                  >
                    {modelos.data.map((modelo) => (
                      <MenuItem key={modelo.id} value={modelo.id}>
                        {modelo.titulo_anuncio}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalUpdate}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={disabled}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </React.Fragment> 
  );
};

const Notificaciones = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Notificaciones</h1>
    <TableRes />
  </Container>
);

export default Notificaciones;