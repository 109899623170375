import React, { useState, useEffect } from 'react';
import { 
  Card,
  Container,
  Col,
  InputGroup,
  Button,
  ButtonGroup,
  Form,
  Row,
  Modal
} from "react-bootstrap";
import ButtonMaterial from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


import { logedServices } from '../../services/logedServices';

const TableRes = () => {

  setLocale({
    mixed: {
      required: 'Este campos es requerido.'
    }
  })
  /*
  setLocale({
    mixed: {
      required: '${path} is requerido'
    }
  })
  */

  const confirmar = (usuario) => {
    confirmAlert({
      title: 'Confirmación',
      message: 'Estás seguro que deseas eliminar el registro?',
      buttons: [
        {
          label: 'Si',
          onClick: () => {
            deleteUsuario(usuario)
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


  const [lastPage, setLastPage] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [searchState, setSearchState] = useState(null);
  const [paramState, setParamState] = useState('id');
  const [orderState, setOrderState] = useState('ASC');
  const [usuarios, setUsuarios] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [dataActive, setDataActive] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleModalAdd = () => setModalAdd(!modalAdd);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleModalUpdate = () => setModalUpdate(!modalUpdate);
  const [modalPasswordUpdate, setModalPasswordUpdate] = useState(false);
  const toggleModalPasswordUpdate = () => setModalPasswordUpdate(!modalPasswordUpdate);

  const [showPassword01, setShowPassword01] = useState(false);
  const [showPassword02, setShowPassword02] = useState(false);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
      getUsuarios(1,searchState,paramState,orderState);
  }, []);

  function getUsuarios(page, search, param, order){
      setDisabled(true);
      logedServices.getUsuarios(page, 10, search, param, order)
      .then(response => {
        if (response.status===200) {
          setPageSelected(page);
          setUsuarios(response.data);
          setLastPage(response.data.meta.last_page);
          setSearchState(search);
          setParamState(param);
          setOrderState(order);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function deleteUsuario(usuario){
      setDisabled(true);
      logedServices.deleteUsuario(usuario.id)
      .then(response => {
        if (response.status===204) {
          getUsuarios(pageSelected, searchState, paramState, orderState);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    getUsuarios(value, searchState, paramState, orderState);
  };

  const handleChangeParam = event => {
    let param_ = event.target.value;
    setParamState(param_);
    getUsuarios(pageSelected, searchState, param_, orderState);
  };

  const handleChangeOrder = event => {
    let order_ = event.target.value;
    setOrderState(order_);
    getUsuarios(pageSelected, searchState, paramState, order_);
  };

  return (
  <React.Fragment> 
    <Card>
      <Card.Body>
          
            
                <Row>
                  {usuarios!==null && usuarios.data.length>0 ? 
                  <React.Fragment>
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                      <Formik
                        initialValues={{
                          busqueda: ''
                        }}
                        onSubmit={(values) => {
                          getUsuarios(1, values.busqueda, paramState, orderState);
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Form.Label
                              className="visually-hidden"
                              htmlFor="busqueda"
                            >
                              Busqueda
                            </Form.Label>
                            <InputGroup>
                              <Form.Control 
                                id="busqueda"
                                name="busqueda"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.busqueda}
                              />
                              <Button type="submit" disabled={disabled} color="primary">
                                <SearchIcon />
                              </Button>
                            </InputGroup>
                          </form>
                        )}
                      </Formik>
                    </Col>
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                      <Form.Label
                        className="visually-hidden"
                        htmlFor="campo"
                      >
                        Orden
                      </Form.Label>
                        <Row>
                          <Col>
                            <Form.Select
                              type="select"
                              value={paramState}
                              onChange={handleChangeParam}
                            >
                              <option value="id">
                                ID
                              </option>
                              <option value="name">
                                Nombre
                              </option>
                              <option value="email">
                                Email
                              </option>
                              <option value="type">
                                Tipo
                              </option>
                            </Form.Select>
                          </Col>
                          <Col>
                            <Form.Select
                              type="select"
                              value={orderState}
                              onChange={handleChangeOrder}
                            >
                              <option value="asc">
                                ASC
                              </option>
                              <option value="desc">
                                DESC
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                    </Col>
                  </React.Fragment> 
                  : ''}
                  <Col xs="12" md="6" lg="4" xl="6" className="mb-3 text-right">
                    <ButtonMaterial disabled={disabled} variant="contained" endIcon={<AddIcon />} onClick={toggleModalAdd}>
                      Agregar
                    </ButtonMaterial>
                  </Col>
                  {usuarios!==null && usuarios.data.length>0 ? 
                  <Col xs="12" className="mb-3">
                    <Table className="">
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>NOMBRE</Th>
                          <Th>EMAIL</Th>
                          <Th>TIPO</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {usuarios.data.map((usuario) => (
                          <Tr key={usuario.id}>
                            <Td>{usuario.id}</Td>
                            <Td>{usuario.name}</Td>
                            <Td>{usuario.email}</Td>
                            <Td>{usuario.type}</Td>
                            <Td>
                              <ButtonGroup
                                className="my-2"
                                size="sm"
                              >
                                <Button disabled={disabled} onClick={() => {setDataActive(usuario);toggleModalPasswordUpdate();}} variant="outline-primary">
                                  Cambiar contraseña
                                </Button>
                                <Button disabled={disabled} onClick={() => {setDataActive(usuario);toggleModalUpdate();}} variant="outline-primary">
                                  Editar
                                </Button>
                                <Button disabled={disabled} onClick={() => {setDataActive(usuario);confirmar(usuario);}} variant="outline-primary">
                                  Borrar
                                </Button>
                              </ButtonGroup>
                            </Td>   
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Col>
                  : ''}
                </Row>
            
          <Pagination className="mt-3" count={lastPage} page={pageSelected} onChange={handleChangePage} />
      </Card.Body>
    </Card>

    <Modal show={modalAdd} onHide={() => setModalAdd(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            name: '',
            email: '',
            type: '',
            repeat_password: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            type: Yup.string().required(),
            email: Yup.string().email().required(),
            password: Yup.string().min(8, "La contraseña debe tener al menos 8 caracteres").required()
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
            ),
            repeat_password: Yup.string()
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .required("Por favor, repite la contraseña")
            .oneOf([Yup.ref('password'), null], 'Las contrraseñas no coinciden')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
            ),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.addUsuario(values)
              .then(response => {
                getUsuarios(1, searchState, paramState, orderState);
                toggleModalAdd();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Nuevo usuario</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                  
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />

                  <TextField
                    select
                    error={Boolean(touched.type && errors.type)}
                    fullWidth
                    helperText={touched.type && errors.type}
                    label="Tipo"
                    margin="normal"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.type}
                    variant="outlined"
                  >
                    <MenuItem value="CLIENTE">
                      Cliente
                    </MenuItem>
                    <MenuItem value="EDITOR">
                      Editor
                    </MenuItem>
                  </TextField>
                  
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Contraseña"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.repeat_password && errors.repeat_password)}
                    fullWidth
                    helperText={touched.repeat_password && errors.repeat_password}
                    label="Confirmar contraseña"
                    margin="normal"
                    name="repeat_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.repeat_password}
                    variant="outlined"
                  />
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalAdd}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalUpdate} onHide={() => setModalUpdate(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            id: dataActive!==null?dataActive.id:'',
            name: dataActive!==null?dataActive.name:'',
            email: dataActive!==null?dataActive.email:'',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            email: Yup.string().email().required(),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updateUsuario(values)
              .then(response => {
                getUsuarios(pageSelected, searchState, paramState, orderState);
                toggleModalUpdate();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Actualizar</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                  
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalUpdate}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalPasswordUpdate} onHide={() => setModalPasswordUpdate(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            id: dataActive!==null?dataActive.id:'',
            password: '',
            repeat_password: ''
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().min(8, "La contraseña debe tener al menos 8 caracteres").required()
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
            ),
            repeat_password: Yup.string()
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .required("Por favor, repite la contraseña")
            .oneOf([Yup.ref('password'), null], 'Las contrraseñas no coinciden')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
            ),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updatePassword(values)
              .then(response => {
                getUsuarios(pageSelected, searchState, paramState, orderState);
                toggleModalPasswordUpdate();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Cambiar contraseña</Modal.Header>
              <Modal.Body>
                  <FormControl variant="outlined" error={Boolean(touched.password && errors.password)} margin="normal" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                    <OutlinedInput
                      name="password"
                      type={showPassword01 ? 'text' : 'password'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword01(!showPassword01) }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword01 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    {Boolean(touched.password && errors.password) ?
                      <FormHelperText id="component-error-text">{errors.password}</FormHelperText>
                    :''}
                  </FormControl>
                  <FormControl variant="outlined" error={Boolean(touched.repeat_password && errors.repeat_password)} margin="normal" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Repite la contraseña</InputLabel>
                    <OutlinedInput
                      name="repeat_password"
                      type={showPassword02 ? 'text' : 'password'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.repeat_password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword02(!showPassword02) }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword02 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Repite la contraseña"
                    />
                    {Boolean(touched.repeat_password && errors.repeat_password) ?
                      <FormHelperText id="component-error-text">{errors.repeat_password}</FormHelperText>
                    :''}
                  </FormControl>
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalPasswordUpdate}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={disabled}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </React.Fragment> 
  );
};

const Usuarios = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Usuarios</h1>
    <TableRes />
  </Container>
);

export default Usuarios;