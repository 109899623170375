import {
  Users,
  List,
  Home,
  BookOpen,
  Bell
} from "react-feather";

const publicSection = [];

const adminSection = [
  {
    href: "/admin/desarrollos",
    icon: List,
    title: "Desarrollos",
  },
  {
    href: "/admin/propiedades",
    icon: Home,
    title: "Propiedades",
  },
  {
    href: "/admin/amenidades",
    icon: BookOpen,
    title: "Amenidades",
  },
  {
    href: "/admin/caracteristicas",
    icon: BookOpen,
    title: "Caracteristicas",
  },
  {
    href: "/admin/usuarios",
    icon: Users,
    title: "Usuarios",
  },
  // {
  //   href: "/admin/notificaciones",
  //   icon: Bell,
  //   title: "Notificaciones",
  // }
];

const editorSection = [
  {
    href: "/admin/desarrollos",
    icon: List,
    title: "Desarrollos",
  },
  {
    href: "/admin/propiedades",
    icon: Home,
    title: "Propiedades",
  },
  {
    href: "/admin/amenidades",
    icon: BookOpen,
    title: "Amenidades",
  },
  {
    href: "/admin/caracteristicas",
    icon: BookOpen,
    title: "Caracteristicas",
  },
  // {
  //   href: "/admin/notificaciones",
  //   icon: Bell,
  //   title: "Notificaciones",
  // }
];

  if (localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined) {
      console.log('user_data===>');
      console.log(localStorage.getItem('user_data'));
  }

  const navItems = localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined ? 
  [
    {
      title: JSON.parse(localStorage.getItem('user_data')).type,
      pages: JSON.parse(localStorage.getItem('user_data')).type==='ADMINISTRADOR'?adminSection:editorSection
    }
  ]
  :
  [
    {
      title: 'PUBLIC',
      pages: publicSection
    }
  ];


export default navItems;
