import React, { useState, useEffect, useCallback } from 'react';
import { 
  Card,
  Container,
  Col,
  InputGroup,
  Button,
  ButtonGroup,
  Form,
  Row,
  Modal,
  FormGroup
} from "react-bootstrap";
import ButtonMaterial from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import FilterIcon from '@mui/icons-material/Filter';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import noImage from "../../assets/img/no_image.svg";

import ImageUploading from 'react-images-uploading';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from "array-move";

import { logedServices } from '../../services/logedServices';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TableRes = () => {

  setLocale({
    mixed: {
      required: 'Este campos es requerido.'
    },
    string: {
      max: 'Valor demasiado largo (máximo ${max} caracteres)'
    }
  })

  const confirmar = (id) => {
    confirmAlert({
      title: 'Confirmación',
      message: 'Estás seguro que deseas eliminar el registro?',
      buttons: [
        {
          label: 'Si',
          onClick: () => deleteDesarrollo(id)
        },
        {
          label: 'No',
          onClick: () => {
            console.log('==>dataActive');
            console.log(dataActive);
          }
        }
      ]
    });
  };

  const IframeMaps = (props) => {
    let coord = props.propCoorMaps;
    if (coord!==null) {
      return (
        <iframe 
          title={coord}
          key={coord}
          src={"https://maps.google.com/maps?q="+coord+"&output=embed"}
          width="100%"
          height="300"
          frameBorder="0"
          style={{border: 0}}></iframe>
      );
    }else{
      return('');
    }
  }

  const [lastPage, setLastPage] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [searchState, setSearchState] = useState(null);
  const [paramState, setParamState] = useState('id');
  const [orderState, setOrderState] = useState('ASC');
  const [desarrollos, setDesarrollos] = useState(null);
  const [estados, setEstados] = useState(null);
  const [municipios, setMunicipios] = useState(null);
  const [imagenes, setImagenes] = useState(null);
  const [imagenesToDelete, setImagenesToDelete] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [dataActive, setDataActive] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleModalAdd = () => setModalAdd(!modalAdd);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleModalUpdate = () => setModalUpdate(!modalUpdate);
  const [modalAmenidades, setModalAmenidades] = useState(false);
  const toggleModalAmenidades = () => setModalAmenidades(!modalAmenidades);
  const [modalImages, setModalImages] = useState(false);
  const toggleModalImages = () => setModalImages(!modalImages);

  /*<---Images upload*/
  const [images, setImages] = React.useState([]);
  const [maxNumberImg, setMaxNumberImg] = React.useState(0);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  /*Images upload--->*/

  /*<---TRANSFER LIST*/
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(null);
  const [right, setRight] = React.useState(null);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const coordenadasRegExp = /^-?\d+\.\d+,\s?-?\d+\.\d+$/;

  function customList(items){
    if (items!==null && items.length>0) {
      return(
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
          <List dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-item-${value}-label`;
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.nombre} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      );
    }else{
      return(
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
        </Paper>
      );
    }
  }
  /*TRANSFER LIST -->*/

  useEffect(() => {
      getDesarrollos(1,searchState,paramState,orderState);
      getEstados();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDesarrollos, getEstados]);

  /*
  function getDesarrollos(page, search, param, order){
      setDisabled(true);
      logedServices.getDesarrollos(page, 10, search, param, order)
      .then(response => {
        if (response.status===200) {
          setPageSelected(page);
          setDesarrollos(response.data);
          setLastPage(response.data.meta.last_page);
          setSearchState(search);
          setParamState(param);
          setOrderState(order);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }
  */

  const getDesarrollos = useCallback((page, search, param, order) => {
      setDisabled(true);
      logedServices.getDesarrollos(page, 10, search, param, order)
      .then(response => {
        if (response.status===200) {
          setPageSelected(page);
          setDesarrollos(response.data);
          setLastPage(response.data.meta.last_page);
          setSearchState(search);
          setParamState(param);
          setOrderState(order);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }, [])

  function deleteDesarrollo(id){
      setDisabled(true);
      logedServices.deleteDesarrollo(id)
      .then(response => {
        if (response.status===204) {
          getDesarrollos(pageSelected, searchState, paramState, orderState);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  /*
  function getEstados(){
      setDisabled(true);
      logedServices.getEstados()
      .then(response => {
        if (response.status===200) {
          setEstados(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }
  */

  const getEstados = useCallback(() => {
      setDisabled(true);
      logedServices.getEstados()
      .then(response => {
        if (response.status===200) {
          setEstados(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }, [])

  function getMunicipios(estado_id){
      setDisabled(true);
      logedServices.getMunicipios(estado_id)
      .then(response => {
        if (response.status===200) {
          setMunicipios(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function getAmenidadesByDesarrollo(desarrollo_id){
      setDisabled(true);
      logedServices.getAmenidadesByDesarrollo(desarrollo_id)
      .then(response => {
        if (response.status===200) {
          setLeft(response.data.unused);
          setRight(response.data.used);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    getDesarrollos(value, searchState, paramState, orderState);
  };

  const handleChangeParam = event => {
    let param_ = event.target.value;
    setParamState(param_);
    getDesarrollos(pageSelected, searchState, param_, orderState);
  };

  const handleChangeOrder = event => {
    let order_ = event.target.value;
    setOrderState(order_);
    getDesarrollos(pageSelected, searchState, paramState, order_);
  };

  function saveAmenidadesDesarrollo(){
      setDisabled(true);
      logedServices.saveAmenidadesDesarrollo(dataActive.id, right)
      .then(response => {
        if (response.status===200) {
          toggleModalAmenidades();
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  /*<---Images*/
  function getImagesByDesarrollo(desarrollo_id){
      setDisabled(true);
      logedServices.getImagesByDesarrollo(desarrollo_id)
      .then(response => {
        if (response.status===200) {
          setImagenes(response.data.data);
          setMaxNumberImg(20-response.data.data.length);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function saveImagesDesarrollo(){
    setDisabled(true);
    var formData = new FormData();
    formData.append("reorder", reorder);
    formData.append("imagenes", JSON.stringify(imagenes));
    let c=0;
    images.forEach(function(image) {
      formData.append("image_"+c, image.file);
        c++;
    });
    logedServices.saveImagesDesarrollo(dataActive.id, formData, imagenesToDelete)
    .then(response => {
      if (response.status===200) {
        setImagenes(null);
        setImagenesToDelete([]);
        setImages([]);
        toggleModalImages();
      }
      setDisabled(false);
    })
    .catch(function (error) {
        return error;
    })
  }

  function onImageRemove(idImagenDesarrollo) {
    const newList = imagenes.filter((imagen) => imagen.id !== idImagenDesarrollo);
    setImagenes(newList);
    setImagenesToDelete(current => [...current, idImagenDesarrollo]);
    setMaxNumberImg(maxNumberImg+1);
  }
  /*Images--->*/

  /*<---Sort*/
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setImagenes((array) => arrayMove(array, oldIndex, newIndex));
    setReorder(true);
  };
  const [reorder, setReorder] = React.useState(false);
  /*Sort---->*/

  return (
  <React.Fragment> 
    <Card>
      <Card.Body>
        <Row>
          {desarrollos!==null && desarrollos.data.length>0 ? 
          <React.Fragment>
            <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
              <Formik
                initialValues={{
                  busqueda: ''
                }}
                onSubmit={(values) => {
                  getDesarrollos(1, values.busqueda, paramState, orderState);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Form.Label
                      className="visually-hidden"
                      htmlFor="busqueda"
                    >
                      Busqueda
                    </Form.Label>
                    <InputGroup>
                      <Form.Control  
                        id="busqueda"
                        name="busqueda"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.busqueda}
                      />
                      <Button type="submit" disabled={disabled} color="primary">
                        <SearchIcon />
                      </Button>
                    </InputGroup>
                  </form>
                )}
              </Formik>
            </Col>
            <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
              <Form.Label
                className="visually-hidden"
                htmlFor="campo"
              >
                Orden
              </Form.Label>
                <Row className="row-cols-lg-auto g-3 align-items-center">
                  <Col>
                    <Form.Select
                      value={paramState}
                      onChange={handleChangeParam}
                    >
                      <option value="id">
                        ID
                      </option>
                      <option value="nombre">
                        Nombre
                      </option>
                      <option value="colonia">
                        Colonia
                      </option>
                      <option value="calle">
                        Calle
                      </option>
                      <option value="descripcion">
                        Descripción
                      </option>
                      <option value="desarrollos.borrador">
                        Borrador
                      </option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      value={orderState}
                      onChange={handleChangeOrder}
                    >
                      <option value="asc">
                        ASC
                      </option>
                      <option value="desc">
                        DESC
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
            </Col>
          </React.Fragment>
          : ''}
          <Col xs="12" md="6" lg="4" xl="6" className="mb-3 text-end">                  
            <ButtonMaterial disabled={disabled} variant="contained" endIcon={<AddIcon />} onClick={toggleModalAdd}>
              Agregar
            </ButtonMaterial>
          </Col>
          {desarrollos!==null && desarrollos.data.length>0 ? 
          <Col xs="12" className="mb-3">
            <Table className="">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>NOMBRE</Th>
                  <Th>DIRECCIÓN</Th>
                  <Th>DESCRIPCIÓN</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {desarrollos.data.map((desarrollo) => (
                  <Tr key={desarrollo.id}>
                    <Td>{desarrollo.id}</Td>
                    <Td>{desarrollo.nombre}
                      {desarrollo.borrador?
                        <div className="block_ctrl"><strong>(BORRADOR)</strong></div>
                      :''}
                    </Td>
                    <Td>
                      Estado: {desarrollo.estado.nombre}<br/>
                      Municipio: {desarrollo.municipio.nombre}<br/>
                      Calle: {desarrollo.calle}<br/>
                      Colonia: {desarrollo.colonia}<br/>
                      Número: {desarrollo.numero}</Td>
                    <Td>{desarrollo.descripcion}</Td>
                    <Td>
                      <ButtonGroup
                        className="my-2"
                        size="sm"
                      >
                        <Button disabled={disabled} onClick={() => {setDataActive(desarrollo);getMunicipios(desarrollo.estado.id);toggleModalUpdate();}} variant="outline-primary">
                          Editar
                        </Button>
                        <Button disabled={disabled} onClick={() => {confirmar(desarrollo.id);}} variant="outline-primary">
                          Borrar
                        </Button>
                        <Button disabled={disabled} onClick={() => {setDataActive(desarrollo);getAmenidadesByDesarrollo(desarrollo.id);toggleModalAmenidades();}}>
                          Amenidades
                        </Button>
                        <Button disabled={disabled} onClick={() => {setDataActive(desarrollo);getImagesByDesarrollo(desarrollo.id);toggleModalImages();}}>
                          Imagenes
                        </Button>
                      </ButtonGroup>
                    </Td>   
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Col>
          : ''}
        </Row>
        <Pagination className="mt-3" count={lastPage} page={pageSelected} onChange={handleChangePage} />
      </Card.Body>
    </Card>

    <Modal show={modalAdd} onHide={() => setModalAdd(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            nombre: '',
            estado: '',
            municipio_id: '',
            calle: '',
            colonia: '',
            numero: '',
            constructora: '',
            logo: '',
            coordenadas: '',
            descripcion: '',
            recorrido: '',
            video: '',
            brochure: '',
            wp: '',
            tel: '',
            borrador: false
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().required(),
            estado: Yup.string().required(),
            municipio_id: Yup.string().required(),
            calle: Yup.string().required(),
            colonia: Yup.string().nullable(),
            numero: Yup.string().nullable(),
            constructora: Yup.string().nullable(),
            logo: Yup.mixed()
              .test("fileSize", "La imagen es demasiado grande, máximo 50Kb", (value) => {
                if (value!==undefined) {
                  return value.size <= 50000;
                }
                return true;
              })
              .test("type", "Solo se aceptan los siguientes formatos: .svg, .png", (value) => {
                  if (value!==undefined) {
                    return value && (
                        value.type === "image/svg+xml" ||
                        value.type === "image/png"
                    );
                  }
                  return true;
            }),
            coordenadas: Yup.string()
              .required('Las coordenadas son requeridas')
              .matches(coordenadasRegExp, 'El formato de las coordenadas es incorrecto'),
            descripcion: Yup.string().required().max(2000),
            recorrido: Yup.string().nullable(),
            video: Yup.string().nullable(),
            brochure: Yup.string().nullable(),
            wp: Yup.string().nullable(),
            tel: Yup.string().nullable()
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.addDesarrollo(values)
              .then(response => {
                getDesarrollos(1, searchState, paramState, orderState);
                toggleModalAdd();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Nuevo desarrollo</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.nombre && errors.nombre)}
                    fullWidth
                    helperText={touched.nombre && errors.nombre}
                    label="Nombre"
                    margin="normal"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nombre}
                    variant="outlined"
                  />
                  {estados!==null && estados.data.length>0 ? 
                  <TextField
                    select
                    error={Boolean(touched.estado && errors.estado)}
                    fullWidth
                    helperText={touched.estado && errors.estado}
                    label="Estado"
                    margin="normal"
                    name="estado"
                    onBlur={handleBlur}
                    onChange={(e) => {handleChange(e); getMunicipios(e.target.value); }}
                    type="text"
                    value={values.estado}
                    variant="outlined"
                  >
                    {estados.data.map((estado) => (
                      <MenuItem key={estado.id} value={estado.id}>
                        {estado.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  {municipios!==null && municipios.data.length>0 ? 
                  <TextField
                    select
                    error={Boolean(touched.municipio_id && errors.municipio_id)}
                    fullWidth
                    helperText={touched.municipio_id && errors.municipio_id}
                    label="Municipio"
                    margin="normal"
                    name="municipio_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.municipio_id}
                    variant="outlined"
                  >
                    {municipios.data.map((municipio) => (
                      <MenuItem key={municipio.id} value={municipio.id}>
                        {municipio.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  <TextField
                    error={Boolean(touched.calle && errors.calle)}
                    fullWidth
                    helperText={touched.calle && errors.calle}
                    label="Calle"
                    margin="normal"
                    name="calle"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.calle}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.colonia && errors.colonia)}
                    fullWidth
                    helperText={touched.colonia && errors.colonia}
                    label="Colonia"
                    margin="normal"
                    name="colonia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.colonia}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.numero && errors.numero)}
                    fullWidth
                    helperText={touched.numero && errors.numero}
                    label="Número"
                    margin="normal"
                    name="numero"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.numero}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.constructora && errors.constructora)}
                    fullWidth
                    helperText={touched.constructora && errors.constructora}
                    label="Constructora"
                    margin="normal"
                    name="constructora"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.constructora}
                    variant="outlined"
                  />

                  <FormGroup className="my-3">
                    <Form.Label htmlFor="logo">
                      Logo de la constructora
                    </Form.Label>
                    <Form.Control
                      isInvalid={Boolean(touched.logo && errors.logo)}
                      id="logo"
                      name="logo"
                      type="file"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("logo", event.currentTarget.files[0]);
                      }}
                    />
                    {touched.logo && errors.logo?
                      <Form.Control.Feedback type="invalid">{touched.logo && errors.logo}</Form.Control.Feedback>
                    :''}
                  </FormGroup>

                  <TextField
                    error={Boolean(touched.coordenadas && errors.coordenadas)}
                    fullWidth
                    helperText={touched.coordenadas && errors.coordenadas}
                    label="Coordenadas"
                    margin="normal"
                    name="coordenadas"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.coordenadas}
                    variant="outlined"
                  />
                  <IframeMaps propCoorMaps={values.coordenadas}/>
                  <TextField
                    multiline
                    rows={4}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    fullWidth
                    helperText={touched.descripcion && errors.descripcion}
                    label="Descripción"
                    margin="normal"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.descripcion}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.recorrido && errors.recorrido)}
                    fullWidth
                    helperText={touched.recorrido && errors.recorrido}
                    label="Recorrido"
                    margin="normal"
                    name="recorrido"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.recorrido}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.video && errors.video)}
                    fullWidth
                    helperText={touched.video && errors.video}
                    label="Video"
                    margin="normal"
                    name="video"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.video}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.brochure && errors.brochure)}
                    fullWidth
                    helperText={touched.brochure && errors.brochure}
                    label="Brochure"
                    margin="normal"
                    name="brochure"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.brochure}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.wp && errors.wp)}
                    fullWidth
                    helperText={touched.wp && errors.wp}
                    label="Whatsapp"
                    margin="normal"
                    name="wp"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.wp}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.tel && errors.tel)}
                    fullWidth
                    helperText={touched.tel && errors.tel}
                    label="Teléfono"
                    margin="normal"
                    name="tel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.tel}
                    variant="outlined"
                  />
                  <FormControlLabel
                    className="m-0"
                    control={
                      <Checkbox
                        name="borrador"
                        checked={values.borrador}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                          '&.Mui-checked': {
                            color: red[600],
                          },
                        }}
                      />
                    }
                    label="Borrador"
                    labelPlacement="start"
                  />
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalAdd}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalUpdate} onHide={() => setModalUpdate(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            id: dataActive!==null && dataActive.id!==null ? dataActive.id : '',
            nombre: dataActive!==null && dataActive.nombre!==null ? dataActive.nombre : '',
            estado: dataActive!==null && dataActive.estado!==null ? dataActive.estado.id : '',
            municipio_id: dataActive!==null && dataActive.municipio_id!==null ? dataActive.municipio.id : '',
            calle: dataActive!==null && dataActive.calle!==null ? dataActive.calle : '',
            colonia: dataActive!==null && dataActive.colonia!==null ? dataActive.colonia : '',
            numero: dataActive!==null && dataActive.numero!==null ? dataActive.numero : '',
            constructora: dataActive!==null && dataActive.constructora!==null ? dataActive.constructora : '',
            logo: '',
            logo_show: dataActive!==null&&dataActive.logo_constructora!==null?dataActive.logo_constructora:'',
            coordenadas: dataActive!==null && dataActive.coordenadas!==null ? dataActive.coordenadas : '',
            descripcion: dataActive!==null && dataActive.descripcion!==null ? dataActive.descripcion : '',
            recorrido: dataActive!==null && dataActive.recorrido!==null ? dataActive.recorrido : '',
            video: dataActive!==null && dataActive.video!==null ? dataActive.video : '',
            brochure: dataActive!==null && dataActive.brochure!==null ? dataActive.brochure : '',
            wp: dataActive!==null && dataActive.wp!==null ? dataActive.wp : '',
            tel: dataActive!==null && dataActive.tel!==null ? dataActive.tel : '',
            borrador: dataActive!==null && dataActive.borrador!==null ? dataActive.borrador : ''
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().required(),
            estado: Yup.string().required(),
            municipio_id: Yup.string().required(),
            calle: Yup.string().required(),
            colonia: Yup.string().required().nullable(),
            numero: Yup.string().required().nullable(),
            constructora: Yup.string().nullable(),
            logo: Yup.mixed()
              .test("fileSize", "La imagen es demasiado grande, máximo 50Kb", (value) => {
                if (value!==undefined) {
                  return value.size <= 50000;
                }
                return true;
              })
              .test("type", "Solo se aceptan los siguientes formatos: .svg, .png", (value) => {
                  if (value!==undefined) {
                    return value && (
                        value.type === "image/svg+xml" ||
                        value.type === "image/png"
                    );
                  }
                  return true;
            }),
            coordenadas: Yup.string()
              .required('Las coordenadas son requeridas')
              .matches(coordenadasRegExp, 'El formato de las coordenadas es incorrecto'),
            descripcion: Yup.string().required(),
            recorrido: Yup.string().nullable(),
            video: Yup.string().nullable(),
            brochure: Yup.string().nullable(),
            wp: Yup.string().nullable(),
            tel: Yup.string().nullable(),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updateDesarrollo(values)
              .then(response => {
                getDesarrollos(pageSelected, searchState, paramState, orderState);
                toggleModalUpdate();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Edición de desarrollo</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.nombre && errors.nombre)}
                    fullWidth
                    helperText={touched.nombre && errors.nombre}
                    label="Nombre"
                    margin="normal"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nombre}
                    variant="outlined"
                  />
                  {estados!==null && estados.data.length>0 ? 
                  <TextField
                    select
                    error={Boolean(touched.estado && errors.estado)}
                    fullWidth
                    helperText={touched.estado && errors.estado}
                    label="Estado"
                    margin="normal"
                    name="estado"
                    onBlur={handleBlur}
                    onChange={(e) => {handleChange(e); getMunicipios(e.target.value); }}
                    type="text"
                    value={values.estado}
                    variant="outlined"
                  >
                    {estados.data.map((estado) => (
                      <MenuItem key={estado.id} value={estado.id}>
                        {estado.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  {municipios!==null && municipios.data.length>0 ? 
                  <TextField
                    select
                    error={Boolean(touched.municipio_id && errors.municipio_id)}
                    fullWidth
                    helperText={touched.municipio_id && errors.municipio_id}
                    label="Municipio"
                    margin="normal"
                    name="municipio_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.municipio_id}
                    variant="outlined"
                  >
                    {municipios.data.map((municipio) => (
                      <MenuItem key={municipio.id} value={municipio.id}>
                        {municipio.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  <TextField
                    error={Boolean(touched.calle && errors.calle)}
                    fullWidth
                    helperText={touched.calle && errors.calle}
                    label="Calle"
                    margin="normal"
                    name="calle"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.calle}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.colonia && errors.colonia)}
                    fullWidth
                    helperText={touched.colonia && errors.colonia}
                    label="Colonia"
                    margin="normal"
                    name="colonia"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.colonia}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.numero && errors.numero)}
                    fullWidth
                    helperText={touched.numero && errors.numero}
                    label="Número"
                    margin="normal"
                    name="numero"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.numero}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.constructora && errors.constructora)}
                    fullWidth
                    helperText={touched.constructora && errors.constructora}
                    label="Constructora"
                    margin="normal"
                    name="constructora"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.constructora}
                    variant="outlined"
                  />
                  {values.logo_show!=='' ?
                    <img 
                      alt={values.nombre}
                      style={{
                        maxWidth: 120,
                        maxHeight: 120,
                        backgroundColor: '#DDDDDD',
                        padding: 15,
                        marginTop: 20
                      }}
                      src={values.logo!==null && values.logo!==undefined && values.logo!==''?URL.createObjectURL(values.logo):process.env.REACT_APP_API_MAIN+values.logo_show}
                    />
                  :
                    <img 
                      alt={values.nombre}
                      style={{
                        maxWidth: 120,
                        maxHeight: 120
                      }}
                      src={noImage}
                    />
                  }
                  <FormGroup className="my-3">
                    <Form.Label htmlFor="logo">
                      Logo de la constructora
                    </Form.Label>
                    <Form.Control
                      isInvalid={Boolean(touched.logo && errors.logo)}
                      id="logo"
                      name="logo"
                      type="file"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("logo", event.currentTarget.files[0]);
                      }}
                    />
                    {touched.logo && errors.logo?
                      <Form.Control.Feedback type="invalid">{touched.logo && errors.logo}</Form.Control.Feedback>
                    :''}
                  </FormGroup>
                  <TextField
                    error={Boolean(touched.coordenadas && errors.coordenadas)}
                    fullWidth
                    helperText={touched.coordenadas && errors.coordenadas}
                    label="Coordenadas"
                    margin="normal"
                    name="coordenadas"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.coordenadas}
                    variant="outlined"
                  />
                  <TextField
                    multiline
                    rows={4}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    fullWidth
                    helperText={touched.descripcion && errors.descripcion}
                    label="Descripción"
                    margin="normal"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.descripcion}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.recorrido && errors.recorrido)}
                    fullWidth
                    helperText={touched.recorrido && errors.recorrido}
                    label="Recorrido"
                    margin="normal"
                    name="recorrido"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.recorrido}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.video && errors.video)}
                    fullWidth
                    helperText={touched.video && errors.video}
                    label="Video"
                    margin="normal"
                    name="video"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.video}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.brochure && errors.brochure)}
                    fullWidth
                    helperText={touched.brochure && errors.brochure}
                    label="Brochure"
                    margin="normal"
                    name="brochure"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.brochure}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.wp && errors.wp)}
                    fullWidth
                    helperText={touched.wp && errors.wp}
                    label="Whatsapp"
                    margin="normal"
                    name="wp"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.wp}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.tel && errors.tel)}
                    fullWidth
                    helperText={touched.tel && errors.tel}
                    label="Teléfono"
                    margin="normal"
                    name="tel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.tel}
                    variant="outlined"
                  />
                  <FormControlLabel
                    className="m-0"
                    control={
                      <Checkbox
                        name="borrador"
                        checked={values.borrador}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                          '&.Mui-checked': {
                            color: red[600],
                          },
                        }}
                      />
                    }
                    label="Borrador"
                    labelPlacement="start"
                  />
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalUpdate}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalAmenidades} onHide={() => setModalAmenidades(false)} backdrop="static" keyboard={false}>
      <Modal.Header>Amenidades</Modal.Header>
      <Modal.Body>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList(left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={left===null || left.length === 0}
                aria-label="move all right"
              >
                ≫
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={right===null || right.length === 0}
                aria-label="move all left"
              >
                ≪
              </ButtonMaterial>
            </Grid>
          </Grid>
          <Grid item>{customList(right)}</Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" type="button" onClick={saveAmenidadesDesarrollo} disabled={disabled}>
          Guardar
        </Button>{' '}
        <Button disabled={disabled} color="secondary" onClick={toggleModalAmenidades}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={modalImages} onHide={() => setModalImages(false)} backdrop="static" keyboard={false}>
      <Modal.Header>Imagenes</Modal.Header>
      <Modal.Body>
        {imagenes!==null && imagenes.length>0 ?
          <React.Fragment> 
            <div className="block_ctrl">
              <span className="h4">Cargadas</span>
            </div>
            <div className="block_ctrl pb-4">
              <div className="row justify-content-center align-items-center">
                <SortableList
                  onSortEnd={onSortEnd}
                  className="list_sort"
                  draggedItemClassName="dragged_s"
                >
                  {imagenes.map((item) => (
                    <SortableItem key={item.id}>
                      <div className="item">
                        <div className="block_ctrl">
                          <img
                            className="block_ctrl csr_image"
                            alt={item.imagen.nombre}
                            src={`${process.env.REACT_APP_API_MAIN+item.imagen.nombre}?w=248&fit=crop&auto=format`}
                            srcSet={`${process.env.REACT_APP_API_MAIN+item.imagen.nombre}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            loading="lazy"
                          />
                          <div className="block_ctrl pt-1 text-center">
                            <ButtonMaterial variant="outlined" startIcon={<DeleteIcon />} onClick={() => onImageRemove(item.id)}>
                              Borrar
                            </ButtonMaterial>
                          </div>
                        </div>
                      </div>
                    </SortableItem>
                  ))}
                </SortableList>
              </div>
            </div>
          </React.Fragment> 
        :''}
        <div className="block_ctrl">
          <span className="h4">Por cargar</span>
        </div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumberImg}
          maxFileSize={209715}
          acceptType={['jpg', 'jpeg', 'png', 'svg']}
          resolutionType={'more'}
          resolutionWidth={500}
          resolutionHeight={500}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <ButtonMaterial
                variant="contained"
                endIcon={<SendIcon />}
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                disabled={maxNumberImg===0}
                {...dragProps}
              >
                Carga hasta {maxNumberImg>1?maxNumberImg+' imágenes':maxNumberImg+' imagen'} 
              </ButtonMaterial>
              &nbsp;
              <IconButton onClick={onImageRemoveAll} color="primary" aria-label="add to shopping cart">
                <DeleteIcon />
              </IconButton>
              <div className="block_ctrl pt-2">
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image['data_url']} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <ButtonMaterial
                        variant="outlined"
                        startIcon={<FilterIcon />}
                        onClick={() => onImageUpdate(index)}
                        size="small"
                      >Reemplazar</ButtonMaterial>
                      <ButtonMaterial
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => onImageRemove(index)}
                        size="small"
                      >Borrar</ButtonMaterial>
                    </div>
                  </div>
                ))}
              </div>
              {errors?
                <div className="block_ctrl pt-2">
                  {errors.maxNumber && <span>Puedes seleccionar hasta {maxNumberImg} imágenes.</span>}
                  {errors.acceptType && <span>Las extensiones permitidas son JPEG, JPG, PNG y SVG.</span>}
                  {errors.maxFileSize && <span>La imagen excede el peso permitido de 200Kb.</span>}
                  {errors.resolution && <span>La imagen es demasiado pequeña.</span>}
                </div>
              :''}
            </div>
          )}
        </ImageUploading>

      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" type="button" onClick={saveImagesDesarrollo} disabled={disabled}>
          Guardar
        </Button>{' '}
        <Button disabled={disabled} color="secondary" onClick={toggleModalImages}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={disabled}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </React.Fragment> 
  );
};

const Desarrollos = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Desarrollos</h1>
    <TableRes />
  </Container>
);

export default Desarrollos;