import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";

import Main from "../components/Main";

const Auth = ({ children }) => {

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined) {
      navigate("/404");
      if (JSON.parse(localStorage.getItem('user_data')).type==='ADMINISTRADOR' || JSON.parse(localStorage.getItem('user_data')).type==='EDITOR') {
        navigate("admin/desarrollos");
      }else{
        navigate("/404");
      }
    }
  }, []);

  return(
    <React.Fragment>
      <Main className="d-flex w-100 justify-content-center">
        <Container className="d-flex flex-column">
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                {children}
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </Main>
    </React.Fragment>
  )
}

export default Auth;
