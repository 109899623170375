import { axiosApiInstance, axiosApiInstanceFormData, axiosApiInstanceHeaders } from './manageToken'
import { showToastr, toastrError } from '../utils/toast';

const logoutAndClearLocalStorage = () => {
  return new Promise((resolve, reject) => {
    axiosApiInstance.post(`${process.env.REACT_APP_API_CALLS}logout`)
      .then(response => {
        localStorage.clear();
        showToastr('info', 'Sesión cerrada', 'Has salido de tu cuenta.', 2500, true, true, 'top-right');
        resolve(true);
      })
      .catch(error => {
        toastrError(error);
        reject(error);
      });
  });
};

function getModelos(page, limit, search, param, order){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}modelos/cpanel`,
      { params: {
          page: page,
          limit: limit,
          search: search,
          param: param,
          order: order
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAllDesarrollos(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}desarrollos/all/cpanel`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addModelo(object_data, specs){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_CALLS}modelos`,
      {
        specs: specs,
        ...object_data
      }
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nueva propiedad.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateModelo(object_data, specsByModelo){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_CALLS}modelos/`+object_data.id,
      {
        specsByModelo: specsByModelo,
        ...object_data
      }
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La propiedad ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteModelo(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_CALLS}modelos/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'La propiedad ha sido eliminada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function getDesarrollos(page, limit, search, param, order){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}desarrollos/cpanel`,
      { params: {
          page: page,
          limit: limit,
          search: search,
          param: param,
          order: order
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getEstados(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}estados`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getMunicipios($estado_id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}municipios`,
      { params: {
          estado_id: $estado_id
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addDesarrollo(object_data){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}desarrollos`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nuevo desarrollo.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateDesarrollo(object_data){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}desarrollos/`+object_data.id+`/update`,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El desarrollo ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteDesarrollo(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_CALLS}desarrollos/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'El desarrollo ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAmenidades(page, limit, search, param, order){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}amenidads`,
      { params: {
          page: page,
          limit: limit,
          search: search,
          param: param,
          order: order
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addAmenidad(object_data){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}amenidads`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nueva amenidad.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteAmenidad(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_CALLS}amenidads/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'La amenidad ha sido eliminada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateAmenidad(object_data){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}amenidads/`+object_data.id+`/update`,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La amenidad ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAmenidadesByDesarrollo(desarrollo_id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}amenidads/`+desarrollo_id+`/desarrollo`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAmenidadesByModelo(modelo_id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}amenidads/`+modelo_id+`/modelo`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function saveAmenidadesDesarrollo(desarrollo_id, obj){
  let jsonObj = JSON.stringify(obj);
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_CALLS}amenidads/`+desarrollo_id+`/desarrollo`,
      { obj: jsonObj }
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El desarrollo ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function saveAmenidadesPropiedad(modelo_id, obj){
  let jsonObj = JSON.stringify(obj);
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_CALLS}amenidads/`+modelo_id+`/modelo`,
      { obj: jsonObj }
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La amenidad ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getUsuarios(page, limit, search, param, order){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}users`,
      { params: {
          page: page,
          limit: limit,
          search: search,
          param: param,
          order: order
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addUsuario(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_CALLS}users`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado un nuevo usuario.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteUsuario(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_CALLS}users/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'El usuario ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updatePassword(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_CALLS}users/`+object_data.id+`/reset`,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El usuario ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateUsuario(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_CALLS}users/`+object_data.id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El usuario ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function saveImagesDesarrollo(desarrollo_id, formData, imagenesToDelete) {
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}imagen/`+desarrollo_id+`/desarrollo/`+JSON.stringify(imagenesToDelete),
      formData
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Has actualizado las imagenes del desarrollo.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getImagesByDesarrollo(desarrollo_id) {
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}imagen/`+desarrollo_id+`/desarrollo`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function saveImagesModelo(modelo_id, FormData, imagenesToDelete) {
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}imagen/`+modelo_id+`/modelo/`+JSON.stringify(imagenesToDelete),
      FormData
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'Has actualizado las imagenes de la propiedad.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getImagesByModelo(modelo_id) {
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}imagen/`+modelo_id+`/modelo`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateProfile(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_CALLS}profile`,
      object_data
    )
    .then(response => {
      if (response.status===200) {
        localStorage.setItem('user_data', JSON.stringify(response.data.data));
        showToastr('success', 'Correcto.', 'Tu perfil ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateCurrentPassword(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_CALLS}profile/password`,
      object_data
    )
    .then(response => {
      if (response.status===200) {
        localStorage.setItem('user_data', JSON.stringify(response.data.data));
        showToastr('success', 'Correcto.', 'Tu contraseña ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getSpecs(page, limit, search, param, order){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}caracteristicas`,
      { params: {
          page: page,
          limit: limit,
          search: search,
          param: param,
          order: order
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addSpec(object_data){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}caracteristicas`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nueva característica.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateSpec(object_data, deleteIcon){
  return axiosApiInstanceFormData.post(
      `${process.env.REACT_APP_API_CALLS}caracteristicas/`+object_data.id+`/update`,
      {
        deleteIcon: deleteIcon,
        ...object_data
      }
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La característica ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAllSpecs() {
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}caracteristicas/all`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getSpecsByModelo(modelo_id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}caracteristicas/`+modelo_id+`/modelo`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteSpec(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_CALLS}caracteristicas/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'La caracteristica ha sido eliminada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}




function getNotis(page, limit, search, param, order){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}notificacions`,
      { params: {
          page: page,
          limit: limit,
          search: search,
          param: param,
          order: order
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addNoti(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_CALLS}notificacions`,
      {
        ...object_data
      }
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nueva propiedad.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateNoti(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_CALLS}notificacions/`+object_data.id,
      {
        ...object_data
      }
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La propiedad ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteNoti(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_CALLS}notificacions/`+id
    )
    .then(response => {
      if (response.status===204) {
          showToastr('success', 'Correcto.', 'La propiedad ha sido eliminada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getModelosFromDesarrollo(desarrollo_id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_CALLS}modelos/`+desarrollo_id+`/desarrollo`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


export const logedServices = {
  getModelos,
  getAllDesarrollos,
  addModelo,
  updateModelo,
  deleteModelo,
  getDesarrollos,
  getEstados,
  getMunicipios,
  addDesarrollo,
  updateDesarrollo,
  deleteDesarrollo,
  getAmenidades,
  addAmenidad,
  deleteAmenidad,
  updateAmenidad,
  getAmenidadesByDesarrollo,
  saveAmenidadesDesarrollo,
  getAmenidadesByModelo,
  saveAmenidadesPropiedad,
  getUsuarios,
  addUsuario,
  deleteUsuario,
  updateUsuario,
  updatePassword,
  saveImagesDesarrollo,
  getImagesByDesarrollo,
  saveImagesModelo,
  getImagesByModelo,
  updateProfile,
  updateCurrentPassword,
  logoutAndClearLocalStorage,
  getSpecs,
  addSpec,
  updateSpec,
  getAllSpecs,
  getSpecsByModelo,
  deleteSpec,
  getNotis,
  addNoti,
  updateNoti,
  deleteNoti,
  getModelosFromDesarrollo,
};