import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Card
} from "react-bootstrap";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';




import { ReactComponent as Logo } from "../../assets/img/logo_fracs.svg";
import { authServices } from '../../services/authServices';

const SignIn = () => {

  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const actionsLogin = (response) => {
    if (response.status === 201) {
      if (response.data.user.type === 'ADMINISTRADOR') {
        window.location.href = process.env.REACT_APP_URL+'admin/desarrollos';
        //navigate("/admin/desarrollos");
      }else if(response.data.user.type === 'EDITOR'){
        window.location.href = process.env.REACT_APP_URL+'admin/desarrollos';
        //navigate("/admin/desarrollos");
      }else{
        navigate("/404");
      }
    }
  }

  return(

  <React.Fragment>
    <div className="text-center mt-4">
      <div className="block_ctrl text-center logo_auth">
        <Logo />
      </div>
      <h2>INICIA SESIÓN</h2>
      <p className="lead">Ingresa a tu cuenta para continuar</p>
    </div>
    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('El email no es valido').required('El email es requerido'),
                password: Yup.string().required('La contraseña es requerida')
              })}
              onSubmit={(values) => {
                setDisabled(true);
                authServices.login(values)
                  .then(response => {
                    actionsLogin(response);
                  })
                  .catch(function (error) {
                  }).then(function () {
                    setDisabled(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    mt={3}
                    mb={1}
                  >
                  </Box>
                  <TextField
                    autoFocus={true}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />
{/*                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Contraseña"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />*/}
                  <FormControl variant="outlined" error={Boolean(touched.password && errors.password)} margin="normal" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                    <OutlinedInput
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    {Boolean(touched.password && errors.password) ?
                      <FormHelperText id="component-error-text">{errors.password}</FormHelperText>
                    :''}
                  </FormControl>
                  <div className="block_ctrl text-center my-2">
                    <Button
                      color="primary"
                      disabled={disabled}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ENTRAR
                    </Button>
                  </div>
                  <div className="block_ctrl text-center">ó</div>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    align="center"
                  >
                    <Link
                      component={RouterLink}
                      to="/reestablecer-contrasena"
                      variant="p"
                    >
                      ¿Has olvidado la contraseña?
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>

  );
};

export default SignIn;